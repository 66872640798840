import { LoadableAfterPaint } from '@confluence/loadable';

export const EngagementInlineDialogLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EngagementInlineDialogWrapper" */ './EngagementInlineDialogWrapper'
			)
		).EngagementInlineDialogWrapper,
});
