import { LoadableLazy } from '@confluence/loadable';

export const WhiteboardGAModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-WhiteboardGAModalManager" */ './WhiteboardGAModalManager'
			)
		).WhiteboardGAModalManager,
});
